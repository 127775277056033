import * as React from 'react'
import { graphql, navigate, PageProps } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import { FormattedMessage } from 'gatsby-plugin-intl'

import { getRedirectLanguage } from '../utils'
import Layout from '../containers/layout'
import Header from '../components/header'
import Footer from '../components/footer'
import Investors from '../components/investors'
import Inquiry from '../components/inquiry'

export const pageQuery = graphql`
  query CompanyInvestorsData {
    allContentfulInvestors(
      filter: { node_locale: { regex: "/^en/" } }
      sort: { fields: priority, order: ASC }
    ) {
      edges {
        node {
          id
          logo {
            localFile {
              childImageSharp {
                gatsbyImageData(height: 80)
              }
            }
          }
          name
          url
          priority
        }
      }
    }
  }
`

const CompanyPage: React.FC<PageProps> = ({ location, pageContext }) => {
  React.useEffect(() => {
    const urlLang = getRedirectLanguage()
    if (location.pathname.startsWith('/en/') && urlLang === '/') {
      const newPathname = location.pathname.replace('/en/', '/')
      void navigate(newPathname)
    }
  }, [location.pathname])

  return (
    <Layout id="content" subtitle="Company" pageContext={pageContext}>
      <section id="top">
        <Header location={location} pageContext={pageContext} />
        <div className="inner">
          <h1 className="title fadeup">Company</h1>
        </div>
      </section>

      <div className="content-wrap">
        <section className="message">
          <div className="inner">
            <div className="message-visual fadeup">
              <StaticImage
                src="../images/message-visual.jpg"
                alt="CEO"
                loading="eager"
              />
            </div>
            <div className="message-text">
              <h2 className="heading fadeup">
                <FormattedMessage id="company_title" />
              </h2>
              <p className="fadeup-delay">
                <p>
                  <FormattedMessage id="company_text_1" />
                </p>
                <p>
                  <FormattedMessage id="company_text_2" />
                </p>
                <p>
                  <FormattedMessage id="company_text_3" />
                </p>
                <p>
                  <FormattedMessage id="company_text_4" />
                </p>
                <p>
                  <FormattedMessage id="company_text_5" />
                </p>
              </p>
              <p className="name fadeup-delay">
                <FormattedMessage id="company_ceo_name" />
              </p>
            </div>
          </div>
        </section>
        <section className="profile fadeup">
          <div className="inner">
            <table>
              <tbody>
                <tr>
                  <th aria-label="Company Name">
                    <FormattedMessage id="company_info_name_head" />
                  </th>
                  <td>
                    <FormattedMessage id="company_info_name_text" />
                  </td>
                </tr>
                <tr>
                  <th aria-label="Place">
                    <FormattedMessage id="company_info_location_head" />
                  </th>
                  <td>
                    <a
                      href="https://www.google.com/maps?ll=35.712072,139.698296&z=14&t=m&hl=ja&gl=JP&mapclient=embed&cid=1594451642423661210"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <FormattedMessage id="company_info_location_text" />
                    </a>
                  </td>
                </tr>
                <tr>
                  <th aria-label="CEO">
                    <FormattedMessage id="company_info_ceo_head" />
                  </th>
                  <td>
                    <FormattedMessage id="company_info_ceo_text" />
                  </td>
                </tr>
                <tr>
                  <th aria-label="Establishment">
                    <FormattedMessage id="company_info_establishment_head" />
                  </th>
                  <td>
                    <FormattedMessage id="company_info_establishment_text" />
                  </td>
                </tr>
              </tbody>
            </table>
            <div className="map">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d6479.273367383293!2d139.69479684306728!3d35.71055722445689!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x60188d39bfffffff%3A0x1620a1522e33529a!2sEmbodyme%2C%20Inc.!5e0!3m2!1sja!2sjp!4v1612714941348!5m2!1sja!2sjp"
                frameBorder={0}
                allowFullScreen
                aria-hidden={false}
                title="map"
              />
            </div>
          </div>
        </section>

        <Investors />
      </div>

      <Inquiry />
      <Footer location={location} />
    </Layout>
  )
}

export default CompanyPage
